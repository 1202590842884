import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/fragments/button';
import InputBox from '../components/fragments/input-box';

import { UserActions } from '../states/actions';

const detail = {
  username: '',
};

const IndexPage = () => {
  const history = useHistory();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState(detail);

  useEffect(() => {
    if (user?.token) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(UserActions.authenticate(userDetail)).catch(console.log);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  return (
    <div style={{ backgroundColor: '#30444E' }} className='min-h-screen overflow-x-hidden flex justify-center items-center'>
      <form className={`max-w-md w-full mx-auto`} onSubmit={handleSubmit}>
        <h1 className='font-semibold text-white text-xl mb-8 text-center'>Sign In</h1>
        <FadeIn className={`space-y-8`}>
          <InputBox placeholder={`Username`} name={`username`} onValueChange={handleInputChange} required={true} />

          <InputBox
            type={`password`}
            placeholder={`Password`}
            name={`password`}
            autoComplete={'current-password'}
            onValueChange={handleInputChange}
            required={true}
          />

          <Button text={`Sign In`} className={`w-full max-w-md mx-auto`} variant={1} />
        </FadeIn>
      </form>
    </div>
  );
};

export default IndexPage;
