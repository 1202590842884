import { FETCH_METRICS, UPDATE_METRICS } from '../type';

const initialState = {};

const productReducer = (metrics = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_METRICS:
      return payload;
    case UPDATE_METRICS:
      return payload;
    default:
      return metrics;
  }
};

export default productReducer;
