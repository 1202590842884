import { combineReducers } from 'redux';
import response from './response';
import user from './user';
import metrics from './metrics';

export default combineReducers({
  response,
  user,
  metrics
});
