import http from '../http';

export const retrieveMetrics = async (from, to) => {
  return http().get(`/admin/analytics/metrics-dashboard?fromDate=${from}&toDate=${to}&type=custom`);
};

const MetricsService = {
  retrieveMetrics,
};

export default MetricsService;
