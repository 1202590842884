import axios from 'axios';
import store from './states/store';
import { apiUrl } from './config';

const http = () => {
  let token = store.getState().user?.token;
  let verifyToken = store.getState().user?.verifyToken;

  axios.defaults.headers.common['source'] = 'web';

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (verifyToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${verifyToken}`;
  }

  return axios.create({
    baseURL: apiUrl,
  });
};

export async function fetcher(...args) {
  let token = store.getState().user?.token;
  let verifyToken = store.getState().user?.verifyToken;

  axios.defaults.headers.common['source'] = 'web';

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (verifyToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${verifyToken}`;
  }

  try {
    const res = await axios.get(...args);
    return res.data;
  } catch (e) {
    return e.message;
  }
}

export default http;
