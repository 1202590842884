import { FETCH_METRICS, UPDATE_METRICS } from '../type';

import { MetricsService } from '../../services';
import { notify, loading } from './response';
import { UserActions } from './index';

export const fetchMetrics = (from, to) => async dispatch => {
  dispatch(loading({ loading: true }));

  if (!from || !to) return;

  try {
    const res = await MetricsService.retrieveMetrics(from, to);

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_METRICS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const updateMetrics = data => async dispatch => {
  dispatch({
    type: UPDATE_METRICS,
    payload: data,
  });
};

const MetricsActions = {
  fetchMetrics,
  updateMetrics,
};

export default MetricsActions;
