import React, { useState, useEffect } from 'react';
import { CgArrowTopRight as ArrowUp, CgArrowBottomRight as ArrowDown } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { Progress } from 'rsuite';

import Heading from '../../components/fragments/heading';
import Chart from '../../components/fragments/chart';
import Loader from '../../components/fragments/loader';

const Home = ({ className, theme, themeDark }) => {
  const { users, surveys, scans, reward, downloads, products } = useSelector(state => state.metrics);
  const [topProducts, setTopProducts] = useState();

  useEffect(() => {
    if (products && products.length > 0) {
      let total = products.reduce((p, n) => {
        return Number(p) + Number(n.count);
      }, 0);
      setTopProducts(
        products.map(d => {
          d.percent = Math.ceil((Number(d.count) / total) * 100);
          return d;
        }),
      );
    }
  }, [products]);

  if (!users || !surveys || !scans || !reward) return <Loader theme={theme} />;

  return (
    <div style={{ color: theme.text_primary }} className={`${className} space-y-10`}>
      <Heading title={'Metrics KPI'} className={'font-semibold -mb-6'} size={2} />
      <section style={{ minHeight: '500px' }} className='flex space-x-10'>
        <div className={`bg-main bg-pink flex-1 p-6 rounded-3xl text-brand_blue flex flex-col justify-center space-y-10`}>
          <div className='text-5xl'>Total Authentications</div>
          <div>
            <div className='font-bold text-7xl overflow-x-auto overflow-y-hidden'>{scans.totalCount.toLocaleString()}</div>
            <div className='text-4xl mt-2 flex items-center space-x-1 font-semibold'>
              {scans.rate.movement === 'upwards' && <ArrowUp />}
              {scans.rate.movement === 'downwards' && <ArrowDown />}
              <div>{Math.ceil(scans.rate.rate)}%</div>
            </div>
          </div>
        </div>
        <div className='flex-1 grid grid-cols-3 gap-10 text-white'>
          <div className={`bg-sub bg-dark-blue flex-1 p-6 rounded-3xl flex flex-col justify-center space-y-4`}>
            <div className='text-lg'>Survey Responses</div>
            <div>
              <div className='font-bold text-3xl overflow-x-auto overflow-y-hidden'>{surveys.totalCount.toLocaleString()}</div>
              <div className='flex items-center space-x-1 font-semibold'>
                {surveys.rate.movement === 'upwards' && <ArrowUp />}
                {surveys.rate.movement === 'downwards' && <ArrowDown />}
                <div>{Math.ceil(surveys.rate.rate)}%</div>
              </div>
            </div>
          </div>
          <div className={`bg-sub bg-light-blue flex-1 p-6 rounded-3xl flex flex-col justify-center space-y-4`}>
            <div className='text-lg'>Downloads</div>
            <div>
              <div className='font-bold text-3xl overflow-x-auto overflow-y-hidden'>
                {(Number(downloads[0].value) + Number(downloads[1].value)).toLocaleString() + '+'}
              </div>
            </div>
          </div>
          <div className={`bg-sub bg-green flex-1 p-6 rounded-3xl text-brand_blue flex flex-col justify-center space-y-4`}>
            <div className='text-lg'>Sign Ups</div>
            <div>
              <div className='font-bold text-3xl overflow-x-auto overflow-y-hidden'>{users.totalCount.toLocaleString()}</div>
              <div className='flex items-center space-x-1 font-semibold'>
                {users.rate.movement === 'upwards' && <ArrowUp />}
                {users.rate.movement === 'downwards' && <ArrowDown />}
                <div>{Math.ceil(users.rate.rate)}%</div>
              </div>
            </div>
          </div>
          <div className={`bg-sub bg-red flex-1 p-6 rounded-3xl flex flex-col justify-center space-y-4`}>
            <div className='text-lg'>Airtime as Reward</div>
            <div>
              <div className='font-bold text-3xl overflow-x-auto overflow-y-hidden'>&#x20A6;{Number(reward.airtimeReward.amount).toLocaleString()}</div>
              <div className='flex items-center space-x-1 font-semibold'>
                {reward.airtimeReward.rate.movement === 'upwards' && <ArrowUp />}
                {reward.airtimeReward.rate.movement === 'downwards' && <ArrowDown />}
                <div>{Math.ceil(reward.airtimeReward.rate.rate)}%</div>
              </div>
            </div>
          </div>
          <div className={`bg-sub bg-red flex-1 p-6 rounded-3xl flex flex-col justify-center space-y-4`}>
            <div className='text-lg'>Airtime as Campaign</div>
            <div>
              <div className='font-bold text-3xl overflow-x-auto overflow-y-hidden'>&#x20A6;{Number(reward.airtimeCampaign.amount).toLocaleString()}</div>
              <div className='flex items-center space-x-1 font-semibold'>
                {reward.airtimeCampaign.rate.movement === 'upwards' && <ArrowUp />}
                {reward.airtimeCampaign.rate.movement === 'downwards' && <ArrowDown />}
                <div>{Math.ceil(reward.airtimeCampaign.rate.rate)}%</div>
              </div>
            </div>
          </div>
          <div className={`bg-sub bg-orange flex-1 p-6 rounded-3xl flex flex-col justify-center space-y-4`}>
            <div className='text-lg'>Cash</div>
            <div>
              <div className='font-bold text-3xl overflow-x-auto overflow-y-hidden'>&#x20A6;{Number(reward.cashReward.amount).toLocaleString()}</div>
              <div className='flex items-center space-x-1 font-semibold'>
                {reward.cashReward.rate.movement === 'upwards' && <ArrowUp />}
                {reward.cashReward.rate.movement === 'downwards' && <ArrowDown />}
                <div>{Math.ceil(reward.cashReward.rate.rate)}%</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='flex space-x-10'>
        <div className='flex flex-col space-y-10 w-3/5'>
          <div style={{ minHeight: '600px', backgroundColor: theme.bg_primary }} className={`rounded-2xl p-6`}>
            <div className='flex items-center space-x-2'>
              <div className='font-semibold text-lg'>Sign ups</div>
              <div
                className={`${
                  themeDark ? `bg-brand_blue text-brand_green` : `text-brand_blue bg-brand_green`
                } flex items-center space-x-1 font-semibold bg-opacity-60 px-2 p-1 rounded-lg text-xs`}
              >
                {users.rate.movement === 'upwards' && <ArrowUp />}
                {users.rate.movement === 'downwards' && <ArrowDown />}
                <div>{Math.ceil(users.rate.rate)}%</div>
              </div>
            </div>

            <Chart type={'sign-ups'} data={users.graphData} color={theme.accent} dark={themeDark} />
          </div>
          <div style={{ minHeight: '500px', backgroundColor: theme.bg_primary }} className={`rounded-2xl p-6`}>
            <div className='flex items-center space-x-2'>
              <div className='font-semibold text-lg'>Authentications</div>
              <div
                className={`${
                  themeDark ? `bg-brand_blue text-blue-200` : `text-brand_blue bg-blue-200`
                } flex items-center space-x-1 font-semibold bg-opacity-60 px-2 p-1 rounded-lg text-xs`}
              >
                {scans.rate.movement === 'upwards' && <ArrowUp />}
                {scans.rate.movement === 'downwards' && <ArrowDown />}
                <div>{Math.ceil(scans.rate.rate)}%</div>
              </div>
            </div>

            <Chart type={'authentications'} data={scans.graphData} color={theme.accent} dark={themeDark} />
          </div>
        </div>
        <div className='flex flex-col space-y-10 w-2/5'>
          <div style={{ backgroundColor: theme.bg_primary }} className={`rounded-2xl p-6 flex-1`}>
            <div className='flex justify-between items-center space-x-4'>
              <div className='font-semibold text-lg'>Age</div>
              {/* <div className='flex items-center space-x-4 relative right-10'>
                <span className='flex items-center space-x-2'>
                  <div className='w-2 h-2 rounded-full bg-brand_green'></div>
                  <span>women</span>
                </span>
                <span className='flex items-center space-x-2'>
                  <div className='w-2 h-2 rounded-full bg-brand_blue'></div>
                  <span>men</span>
                </span>
              </div> */}
            </div>

            <Chart type={'age_range'} data={users.ageRange} color={theme.accent} dark={themeDark} />
          </div>
          <div style={{ backgroundColor: theme.bg_primary }} className={`rounded-2xl p-6 flex-1`}>
            <div className='font-semibold text-lg'>Downloads</div>

            <div className='w-full flex space-x-12 justify-center items-center h-full'>
              <div className='space-y-2 flex-1 flex flex-col items-end'>
                {downloads.map((d, i) => {
                  return (
                    <div key={i} className='grid grid-cols-2 items-center space-x-2 text-lg w-48'>
                      <div className='font-medium'>{d.name === 'Android' ? `Android` : `iOS`}</div>
                      <div
                        style={{ backgroundColor: d.name === 'Android' ? '#153853' : '#46F7AD' }}
                        className={`${d.name === 'Android' ? `text-white` : `text-brand_blue`} min-w-24 p-2 text-center rounded font-bold`}
                      >
                        {Number(d.value).toLocaleString()}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className='flex-1'>
                <div style={{ width: '300px', height: '300px' }} className='flex justify-center items-center relative'>
                  <Chart
                    type={'downloads'}
                    data={downloads.map(d => {
                      if (d.name === 'android_download') {
                        d.name = 'Android';
                        d.color = '#153853';
                        d.value = Number(d.value);
                      }
                      if (d.name === 'ios_download') {
                        d.name = 'iOS';
                        d.color = '#46F7AD';
                        d.value = Number(d.value);
                      }
                      return d;
                    })}
                    color={theme.accent}
                    dark={themeDark}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: theme.bg_primary }} className={`rounded-2xl p-3 pt-6 flex-1`}>
            <div className='font-semibold text-lg px-3 mb-10'>Top 6 Products</div>

            <div className='grid grid-cols-2 gap-x-12 gap-y-8'>
              {topProducts &&
                topProducts.map((d, i) => {
                  return (
                    <div key={i}>
                      <div className='flex justify-between items-center font-medium px-3'>
                        <div className='w-40 h-10 flex items-end'>{d.product}</div>
                        <div className={`${themeDark ? `text-brand_green` : `text-gray-500`} text-xs`}>{`${d.percent}%`}</div>
                      </div>
                      <Progress.Line
                        percent={d.percent}
                        strokeColor={theme.accent}
                        trailColor={theme.bg_secondary}
                        trailWidth={12}
                        strokeWidth={12}
                        showInfo={false}
                        status='active'
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      <section className='flex space-x-10'>
        <div style={{ minHeight: '600px', backgroundColor: theme.bg_primary }} className='flex-1 rounded-2xl p-6'>
          <div className='flex items-center space-x-2'>
            <div className='font-semibold text-lg'>Survey Responses</div>
            <div
              className={`${
                themeDark ? `bg-brand_blue text-blue-200` : `text-brand_blue bg-blue-200`
              } flex items-center space-x-1 font-semibold bg-opacity-60 px-2 p-1 rounded-lg text-xs`}
            >
              {surveys.rate.movement === 'upwards' && <ArrowUp />}
              {surveys.rate.movement === 'downwards' && <ArrowDown />}
              <div>{Math.ceil(surveys.rate.rate)}%</div>
            </div>
          </div>

          <Chart type={'surveys'} data={surveys.graphData} color={theme.accent} dark={themeDark} />
        </div>
        <div style={{ backgroundColor: theme.bg_primary }} className='flex-1 rounded-2xl p-6'>
          <div className='flex items-center space-x-2'>
            <div className='font-semibold text-lg'>Rewards</div>
            {/* <div
              className={`${
                themeDark ? `bg-brand_blue text-blue-200` : `text-brand_blue bg-blue-200`
              } flex items-center space-x-1 font-semibold bg-opacity-60 px-2 p-1 rounded-lg text-xs`}
            >
              <ArrowUp />
              <div>17%</div>
            </div> */}
          </div>

          <div className='h-full flex items-center justify-center space-x-12 px-4'>
            <div className='flex-1 flex justify-end'>
              <div
                style={{ backgroundColor: theme.bg_secondary, width: '400px', height: '400px' }}
                className='rounded-full flex justify-center items-center relative'
              >
                <Chart
                  type={'rewards'}
                  data={[
                    { name: 'Airtime', value: reward.airtimeCampaign.amount + reward.airtimeReward.amount, color: '#f97f9d' },
                    { name: 'Cash', value: reward.cashReward.amount, color: '#ffad65' },
                  ]}
                  color={theme.accent}
                  dark={themeDark}
                />
              </div>
            </div>
            <div className='flex-1 space-y-8'>
              {[
                { name: 'Airtime', value: reward.airtimeCampaign.amount + reward.airtimeReward.amount, color: '#f97f9d' },
                { name: 'Cash', value: reward.cashReward.amount, color: '#ffad65' },
              ].map((d, i) => {
                return (
                  <div key={i} className={`flex space-x-3`}>
                    <div style={{ backgroundColor: d.color }} className='min-h-full w-2 rounded-t-xl rounded-b-xl'></div>
                    <div className='text-lg font-bold'>
                      <div>{d.name}</div>
                      <div style={{ color: themeDark ? d.color : theme.text_primary }}>&#x20A6;{Number(d.value).toLocaleString()}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
