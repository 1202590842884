import { AUTHENTICATE, UPDATE_USER, SIGNOUT } from '../type';
import { UserService } from '../../services';
import { notify, loading } from './response';

export const authenticate = data => async dispatch => {
  dispatch(loading({ loading: true }));

  try {
    const res = await UserService.authenticate(data);

    dispatch(notify({ title: 'Success', message: 'Login successful', type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: AUTHENTICATE,
      payload: { token: res.data.data.token },
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const updateUser = data => async dispatch => {
  dispatch({
    type: UPDATE_USER,
    payload: data,
  });
};

export const signOut = () => async dispatch => {
  dispatch({
    type: SIGNOUT,
  });
};

const UserActions = {
  authenticate,
  updateUser,
  signOut,
};

export default UserActions;
