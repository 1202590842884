import http from '../http';

export const authenticate = async data => {
  return http().post('/auth/signin', data);
};

const UserService = {
  authenticate,
};

export default UserService;
