import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch } from 'react-redux';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

import { UserActions, MetricsActions } from '../../states/actions';

import { formatDate, getToday } from '../../util';
import Logo from '../fragments/logo';

import Home from '../../pages/home';

const counts = {
  Day: 0,
  Week: 7,
  Month: 30,
  Year: 365,
};

const navigation = [
  { name: 'Day', current: false },
  { name: 'Week', current: false },
  { name: 'Month', current: false },
  { name: 'Year', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const themes = {
  dark: {
    bg_primary: '#30444E',
    bg_secondary: '#2A3C44',
    text_primary: '#FFFFFF',
    text_secondary: '#C5E6FF',
    accent: '#46F7AD',
  },
  light: {
    bg_primary: '#FFFFFF',
    bg_secondary: '#EFF3FE',
    text_primary: '#153853',
    text_secondary: '#C5E6FF',
    accent: '#153853',
  },
};

const Base = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [navItems, setNavItems] = useState(navigation);
  const [currentItem, setCurrentItem] = useState();
  const [themeDark, setThemeDark] = useState(JSON.parse(localStorage.getItem('themeDark')));
  const [theme, setTheme] = useState(themes.dark);
  const [value, setValue] = useState([null, null]);

  useEffect(() => {
    let localItem = JSON.parse(localStorage.getItem('current'));
    let rangeSet = JSON.parse(localStorage.getItem('isRangeSet'));
    if (localItem && !rangeSet) {
      setNavItems(
        navigation.map(item => {
          if (localItem.name === item.name) {
            setValue([null, null]);
            item.current = true;
          } else {
            item.current = false;
          }
          return item;
        }),
      );
    } else if (rangeSet) {
      let range = JSON.parse(localStorage.getItem('range'));
      setValue(range.map(d => new Date(d)));
    } else if (!rangeSet) {
      setNavItems(
        navigation.map(item => {
          if (navigation[3].name === item.name) {
            setValue([null, null]);
            item.current = true;
          } else {
            item.current = false;
          }
          return item;
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (currentItem) {
      localStorage.setItem('isRangeSet', false);
      localStorage.setItem('current', JSON.stringify(currentItem));
      let daysCount = counts[currentItem.name];
      const prev = moment(getToday()).subtract(daysCount, 'days');
      const fromDate = formatDate(prev._d, 'yyyy-MM-dd');
      setValue([new Date(fromDate), new Date(getToday())]);
    }
  }, [currentItem]);

  useEffect(() => {
    let interval;
    if (value && value[0] && value[1]) {
      const from = formatDate(value[0], 'yyyy-MM-dd');
      const to = formatDate(value[1], 'yyyy-MM-dd');
      localStorage.setItem('range', JSON.stringify([from, to]));
      dispatch(MetricsActions.fetchMetrics(from, to));
      interval = setInterval(() => {
        dispatch(MetricsActions.fetchMetrics(from, to));
      }, 60000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (themeDark) {
      setTheme(themes.dark);
      localStorage.setItem('themeDark', true);
    } else {
      setTheme(themes.light);
      localStorage.setItem('themeDark', false);
    }
  }, [themeDark]);

  useEffect(() => {
    if (navItems && navItems.length > 0) {
      navItems.forEach(item => {
        if (item.current) {
          setCurrentItem(item);
        }
      });
    }
  }, [navItems]);

  const setItem = selected => {
    setNavItems(
      navigation.map(item => {
        if (selected.name === item.name) {
          setValue([null, null]);
          item.current = true;
        } else {
          item.current = false;
        }
        return item;
      }),
    );
  };

  const onDateRangeChange = data => {
    localStorage.setItem('isRangeSet', true);
    localStorage.removeItem('current');
    setNavItems(
      navigation.map(item => {
        item.current = false;
        return item;
      }),
    );
    setValue(data);
  };

  const signout = () => {
    dispatch(UserActions.signOut());
    localStorage.removeItem('chekkit-act');
    history.push('/signin');
  };

  return (
    <div style={{ minWidth: '1600px' }} className='min-h-screen w-full flex flex-col'>
      <div style={{ backgroundColor: theme.bg_primary }} className={`p-4 md:px-12`}>
        <FadeIn className='flex justify-between items-center'>
          <Logo size={180} dark={themeDark} />

          <div className='flex items-center space-x-3 sm:space-x-6'>
            {navItems.map(item => (
              <div
                key={item.name}
                onClick={() => setItem(item)}
                className={classNames(item.current ? 'slate-2' : '', 'group text-gray-400 min-w-16 text-center py-1 text-sm font-medium cursor-pointer')}
              >
                {item.name}
              </div>
            ))}
            <div className={`border ${themeDark ? `border-gray-200` : `border-gray-600`} rounded-lg overflow-hidden border-opacity-40`}>
              <DateRangePicker appearance='subtle' value={value} onChange={onDateRangeChange} placeholder='Select Date Range' placement='bottomEnd' />
            </div>

            <div className='flex items-center space-x-3 sm:space-x-6'>
              <div onClick={() => setThemeDark(!themeDark)} className='w-6 h-6 cursor-pointer'>
                {themeDark ? <MdLightMode className='w-full h-full text-yellow-50' /> : <MdDarkMode className='w-full h-full text-brand_blue' />}
              </div>
            </div>
          </div>
        </FadeIn>
      </div>

      <main style={{ backgroundColor: theme.bg_secondary }} className='flex-1 flex flex-col'>
        <div className='flex-1'>
          <Home theme={theme} themeDark={themeDark} className={`p-4 md:px-12`} />
        </div>
        <div className='p-6 text-center -mt-2'>
          <div onClick={signout} className='text-gray-500 cursor-pointer hover:text-gray-300'>
            Sign Out
          </div>
        </div>
      </main>
    </div>
  );
};

export default Base;
