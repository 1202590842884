// user
export const AUTHENTICATE = 'AUTHENTICATE';
export const UPDATE_USER = 'UPDATE_USER';
export const SIGNOUT = 'SIGNOUT';

// response
export const NOTIFY = 'NOTIFY';
export const LOADING = 'LOADING';

// Metrics
export const FETCH_METRICS = 'FETCH_METRICS';
export const UPDATE_METRICS = 'UPDATE_METRICS';
