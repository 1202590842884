import React, { useState, useEffect } from 'react';
import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, CartesianGrid } from 'recharts';

const Chart = ({ data, type, color, dark }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (type === 'downloads') {
      setTotal(
        data.reduce((p, n) => {
          return p + n.value;
        }, 0),
      );
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      {type === 'sign-ups' && (
        <div>
          <div className={`overflow-auto flex items-center justify-start py-12`}>
            <div className='-ml-12 vertical-lr transform -rotate-90 font-medium  flex-shrink-0'>Number of users</div>
            <div style={{ width: '100%', height: 600 }} className='text-center space-y-6 -ml-16'>
              <ResponsiveContainer>
                <AreaChart
                  margin={{
                    bottom: 40,
                    right: 40,
                    left: 20,
                  }}
                  data={data}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={color} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={color} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={color} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={color} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray='3 3' stroke='#dadada' opacity={`${dark ? 0.4 : 0.7}`} />
                  <XAxis dataKey='date' stroke={`${dark ? '#dadada' : 'gray'}`} tick={<CustomizedAxisTick dark={dark} />} />
                  <YAxis dataKey='count' stroke={`${dark ? '#dadada' : 'gray'}`} />
                  <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip name={'Sign Ups'} type='sign-ups' />} />

                  <Area
                    strokeLinecap='round'
                    type='monotone'
                    dataKey='count'
                    stroke={color}
                    strokeWidth='4'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                    activeDot={{ r: 6 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <div className='font-medium'>Date</div>
            </div>
          </div>
        </div>
      )}

      {type === 'surveys' && (
        <div>
          <div className={`overflow-auto flex items-center justify-start py-12`}>
            <div className='-ml-12 vertical-lr transform -rotate-90 font-medium  flex-shrink-0'>Number of response</div>
            <div style={{ width: '100%', height: 600 }} className='text-center space-y-6 -ml-16'>
              <ResponsiveContainer>
                <AreaChart
                  margin={{
                    bottom: 40,
                    right: 20,
                    left: 10,
                  }}
                  data={data}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={color} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={color} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={color} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={color} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray='3 3' stroke='#dadada' opacity={`${dark ? 0.4 : 0.7}`} />
                  <XAxis dataKey='date' stroke={`${dark ? '#dadada' : 'gray'}`} tick={<CustomizedAxisTick dark={dark} />} />
                  <YAxis dataKey='count' stroke={`${dark ? '#dadada' : 'gray'}`} />
                  <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip name={'Responses'} type='surveys' />} />
                  <Area
                    strokeLinecap='round'
                    type='monotone'
                    dataKey='count'
                    stroke={color}
                    strokeWidth='4'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                    activeDot={{ r: 6 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <div className='font-medium'>Date</div>
            </div>
          </div>
        </div>
      )}

      {type === 'authentications' && (
        <div>
          <div className={`overflow-auto flex items-center justify-start py-12`}>
            <div className='-ml-20 vertical-lr transform -rotate-90 font-medium  flex-shrink-0'>Number of authentications</div>
            <div style={{ width: '100%', height: 600 }} className='text-center space-y-6 -ml-24'>
              <ResponsiveContainer>
                <AreaChart
                  margin={{
                    bottom: 40,
                    right: 40,
                    left: 20,
                  }}
                  data={data}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={color} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={color} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={color} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={color} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray='3 3' stroke='#dadada' opacity={`${dark ? 0.4 : 0.7}`} />
                  <XAxis dataKey='date' stroke={`${dark ? '#dadada' : 'gray'}`} tick={<CustomizedAxisTick dark={dark} />} />
                  <YAxis dataKey='count' stroke={`${dark ? '#dadada' : 'gray'}`} />
                  <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip name={'Authentications'} type='authentications' />} />
                  <Area
                    strokeLinecap='round'
                    type='monotone'
                    dataKey='count'
                    stroke={color}
                    strokeWidth='4'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                    activeDot={{ r: 6 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <div className='font-medium'>Date</div>
            </div>
          </div>
        </div>
      )}

      {type === 'age_range' && (
        <div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform -rotate-90 font-medium  flex-shrink-0'>Percent</div>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6 relative right-10'>
              <ResponsiveContainer>
                <BarChart data={data}>
                  <CartesianGrid strokeDasharray='3 3' stroke='#dadada' opacity={`${dark ? 0.4 : 0.7}`} />
                  <XAxis dataKey='age_range' stroke={`${dark ? '#dadada' : 'gray'}`} />
                  {/* <YAxis dataKey='women' type='number' domain={[0, 100]} stroke={`${dark ? '#dadada' : 'gray'}`} /> */}
                  <YAxis dataKey='count' type='number' domain={[0, 100]} stroke={`${dark ? '#dadada' : 'gray'}`} />
                  <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip name={'Users'} type='age_range' />} />

                  <Bar dataKey='count' fill={color} />
                  {/* <Bar dataKey='men' fill='#153853' /> */}
                </BarChart>
              </ResponsiveContainer>
              <div className='font-medium'>Age range</div>
            </div>
          </div>
        </div>
      )}
      {type === 'downloads' && (
        <div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div style={{ width: 300, height: 300 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={data}
                    label={args => renderCustomizedLabel(args, total, dark, false)}
                    cx='50%'
                    cy='50%'
                    isAnimationActive={false}
                    labelLine={false}
                    outerRadius={150}
                    innerRadius={60}
                    strokeWidth={0}
                    fill='#8884d8'
                    dataKey='value'
                  >
                    {data.map((entry, index) => (
                      <Cell key={index} fill={entry.color} />
                    ))}
                  </Pie>

                  <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip type='downloads' />} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}

      {type === 'rewards' && (
        <div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div style={{ width: 400, height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={data}
                    label={args => renderCustomizedLabel(args, total, dark, true)}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    isAnimationActive={false}
                    outerRadius={160}
                    innerRadius={60}
                    strokeWidth={0}
                    fill='#8884d8'
                    dataKey='value'
                  >
                    {data.map((entry, index) => (
                      <Cell key={index} fill={entry.color} />
                    ))}
                  </Pie>

                  <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip type='rewards' />} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }, total, dark, nolabel) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      {!nolabel && (
        <text x={cx} y={cy} dy={8} textAnchor='middle'>
          <tspan fill={`${dark ? `white` : 'gray'}`} x={cx} y={cy - 14} dy='8'>
            total
          </tspan>
          <tspan fill={`${dark ? `white` : '#153853'}`} style={{ fontSize: 26, fontWeight: '600' }} x={cx} y={cy + 14} dy='8'>
            {total.toLocaleString()}
          </tspan>
        </text>
      )}
      <text x={x + 10} y={y} z={2000} fill='white' textAnchor={x > cx ? 'start' : 'end'} style={{ fontSize: 20, fontWeight: '600' }} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};

const CustomizedAxisTick = ({ x, y, stroke, payload, dark }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='end' fill={`${dark ? '#dadada' : 'gray'}`} transform='rotate(-35)'>
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, type, name }) => {
  if (active && payload && payload.length) {
    return (
      <>
        {type === 'downloads' && (
          <div className='bg-white rounded-lg px-8 py-2 shadow'>
            <div className='flex flex-col items-center'>
              <div className='text-gray-600 text-sm'>{payload[0].name}</div>
              <div className='font-semibold text-brand_blue text-lg'>{Number(payload[0].value).toLocaleString()}</div>
            </div>
          </div>
        )}

        {type === 'rewards' && (
          <div className='bg-white rounded-lg px-8 py-2 shadow'>
            <div className='flex flex-col items-center'>
              <div className='text-gray-600 text-sm'>{payload[0].name}</div>
              <div className='font-semibold text-brand_blue text-lg'>&#x20A6;{Number(payload[0].value).toLocaleString()}</div>
            </div>
          </div>
        )}

        {type !== 'rewards' && type !== 'downloads' && (
          <div className='text-center bg-white rounded-lg px-8 py-2 shadow flex items-center space-x-4'>
            {type !== 'age_range' && (
              <div>
                <div className='text-gray-600 text-sm'>Date</div>
                <div className='font-semibold text-brand_blue text-lg'>{payload[0].payload.date}</div>
              </div>
            )}

            <div>
              <div className='text-gray-600 text-sm'>{name}</div>
              <div className='font-semibold text-brand_blue text-lg'>{Number(payload[0].value).toLocaleString()}</div>
            </div>
          </div>
        )}
      </>
    );
  }

  return null;
};

export default Chart;
