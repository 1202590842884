import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';

import IndexPage from './pages';
import Base from './components/layout/base';
import NotFound from './pages/404-page';

import { UserActions, MetricsActions } from './states/actions';
import { notify } from './states/actions/response';

function App() {
  const user = useSelector(state => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const response = useSelector(state => state.response);

  useEffect(() => {
    let token = localStorage.getItem('chekkit-act');
    // alert(token);
    if (token) {
      dispatch(UserActions.updateUser({ token }));
      history.push(location.pathname);
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user?.token) {
      localStorage.setItem('chekkit-act', user.token);
      history.push('/');
      dispatch(MetricsActions.fetchMetrics());
    }
    // eslint-disable-next-line
  }, [user?.token]);

  useEffect(() => {
    if (response.type) {
      let msg = '';
      if (typeof response.message === 'string') {
        msg = response.message;
      } else {
        response.message.forEach(msg => {
          Object.keys(msg).forEach(key => {
            let str = `${key} Error`;
            let title = str.charAt(0).toUpperCase() + str.slice(1);
            let message = msg[key];
            showNotification(response, message, title);
          });
        });
        return;
      }
      showNotification(response, msg);
    } // eslint-disable-next-line
  }, [response]);

  const showNotification = (response, message, title) => {
    store.addNotification({
      message: message,
      type: response.type,
      title: title ? title : response.title,
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 2000,
        onScreen: true,
        pauseOnHover: true,
        showIcon: true,
      },
      onRemoval: () => {
        let timer;
        (() => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            dispatch(notify({ title: '', message: '', type: '', loading: false }));
          }, 2000);
        })();
      },
    });
  };

  return (
    <>
      <ReactNotification isMobile={true} />

      <Switch location={location}>
        <Route exact path={'/'}>
          <>{user?.token ? <Base /> : <Redirect to={'/signin'} />}</>
        </Route>

        <Route exact path={'/signin'}>
          <IndexPage />
        </Route>

        <Route
          render={() => {
            return (
              <div className={`bg`}>
                <NotFound />;
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
}

export default App;
